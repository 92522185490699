<template>
  <div>
    <div slot="header">
      <div class="table_title" style="text-align: center">
        <h2>{{ tableData.project_name }}项目{{ title }}</h2>
      </div>
    </div>
    <br>
    <el-row class="row">
      <h3>报名时间：{{ tableData.expect_start }} 至 {{ tableData.except_end }}</h3>
      <h3>项目状态：
        {{
          tableData.status === 1 ? '进行中' : tableData.status === 2 ? '项目完结' : tableData.status === 3 ? '已作废' : '/'
        }}</h3>
      <h3>报名状态：
        {{
          tableData.is_apply === 1 ? '报名中' : tableData.is_apply === 2 ? '不可报名' : tableData.is_apply === 3 ? '结束报名' : ''
        }}</h3>
      <h3> 报名人数： {{ tableData.apply_count || '暂无' }}</h3>
    </el-row>
  </div>
</template>

<script>
export default {
  name: 'inquiriesTable',
  props: {
    tableData: {
      type: Object,
      required: true
    },
    title: {
      type: String
    }
  }
}
</script>

<style scoped>

</style>