<template>
    <div>
        <el-card shadow="hover">
            <div>
                <el-row :gutter="15">
                    <el-col :span="24">
                        <span class="is-label">所在地区：</span>
                        <span class="is-value">{{ data?.city ?? '/' }}</span>
                    </el-col>
                    <el-col :span="24">
                        <span class="is-label">粉丝(万)：</span>
                        <span class="is-value">{{ $utils.numberFormat(data?.fans_total / 10000, 2) }}</span>
                    </el-col>
                    <el-col :span="24">
                        <span class="is-label">赞藏数：</span>
                        <span class="is-value">{{ data?.zc_total ?? '/' }} 万</span>
                    </el-col>
                    <el-col :span="24">
                        <span class="is-label">简介：</span>
                        <span class="is-value">{{ data?.intro || '/' }} </span>
                    </el-col>
                </el-row>
            </div>
            <div>
                <el-tag class="p-service" type="primary" v-if="data?.opened_star==='Y'">开通星图</el-tag>
                <el-tag class="p-service" type="primary" v-if="data?.opened_window==='Y'">开通橱窗</el-tag>
                <el-tag class="p-service" type="primary" v-if="data?.is_partner==='Y'">为品牌合作人</el-tag>
                <el-tag class="p-service" type="primary" v-if="data?.opened_cart==='Y'">开通购物车</el-tag>
            </div>
        </el-card>
    </div>
</template>

<script>
  export default {
    name: 'PanelPlatformInfo',
    props: {
      data: {
        type: Object
      }
    },
    methods: {
      openUrl(href) {
        let url = ['http://', 'https://', '//'].indexOf(href) > -1 ? href : `https://${href}`
        window.open(url, '_blank')
      }
    }
  }
</script>

<style scoped>
    .p-service {
        margin: 5px;
    }

    .is-label {
        display: inline-block;
        font-weight: 600;
        text-align: right;
    }

</style>